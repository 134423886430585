import { render, staticRenderFns } from "./clarifiedBy.html?vue&type=template&id=2f80bf49&scoped=true&external"
import script from "./clarifiedBy.js?vue&type=script&lang=js&external"
export * from "./clarifiedBy.js?vue&type=script&lang=js&external"
import style0 from "./clarifiedBy.scss?vue&type=style&index=0&id=2f80bf49&prod&scoped=true&lang=scss&external"
import style1 from "./index.vue?vue&type=style&index=1&id=2f80bf49&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2f80bf49",
  null
  
)

export default component.exports