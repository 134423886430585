export default {
    name: "neo-ext-link",
    components: {},
    props: ["url", "size"],
    data() {
        return {};
    },
    computed: {},
    mounted() {},
    methods: {
        onExtLink() {
            window.open(this.url, "_blank");
        },
    },
};
