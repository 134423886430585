export default {
    name: "neo-radio",
    components: {},
    props: {
        value: {
            type: Boolean,
            default: false,
        },
        label: String,
        size: {
            type: String,
            default: "1.25rem",
        },
    },
    data() {
        return {};
    },
    computed: {},
    mounted() {},
    methods: {},
};
