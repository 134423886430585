export default {
    name: "n-loader",
    components: {},
    props: [],
    data() {
        return {};
    },
    computed: {},
    mounted() {},
    methods: {},
};
