import NeoLoader from "@/components/loader";
import Tags from "@/components/tags";
import Tag from "@/components/tag";
import {get, startCase} from "lodash";

import {EventBus} from "@/main.js";


export default {
    name: "companyWatch",
    components: {
       
        NeoLoader,
        "neo-tags": Tags,
        "neo-tag": Tag,
     
    },
    props: {
        companyDetails: {type: Object},
        field_type: {
            type: String,
        },
        isComparisonModeOn: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            startCase: startCase,
            stabilizing: false,
            refBtnClick: {
                summary: true,
                companyDetails: true,
                address: true,
                account: true,
                financial: true,
                director: true,
                shareholder: true,
                event: true,
                refCurrentAppointment: true,
                refPreviousAppointment: true,
            },
            emitVal: {
                CurrentAppointments: false,
                PreviousAppointments: false,
            },
            isFetchData: false,
            printId: "",
            printName: "",
            isPrinted: false,
        };
    },
    computed: {
        isOfficersTable() {
            const companyDetails = this.companyDetails;
            return companyDetails && companyDetails.api_all_data && companyDetails.api_all_data.current_directors && companyDetails.api_all_data.current_directors.length;
        },
        isPrevOfficersTable() {
            const companyDetails = this.companyDetails;
            return companyDetails && companyDetails.api_all_data && companyDetails.api_all_data.previous_directors && companyDetails.api_all_data.previous_directors.length;
        },
    },
    mounted() {
        this.highlightChanges();
    },
    methods: {
        get,
        getAnalyserSource(key, matchKeyword) {
            if (key && typeof key === "string" && key?.toLowerCase() === matchKeyword) return "name";
            else return "company_name";
        },
        commaJoin(...values) {
            return values.filter((e) => e != null && e != "").join(", ");
        },
        joinArray(arr, sep = ", ") {
            return arr.filter((e) => e != null && e != "").join(sep);
        },
        formatDateFromISO(format = "d MMMM yyyy") {
            return format;
        },
        async fetchMoreInfo(details, api_call_type = null, recall = true) {
            if (this.isComparisonModeOn && recall) {
                let otherVersion = this.$parent.$parent.$children.find((el) => el.companyDetails.api_all_data.doc_id !== this.companyDetails.api_all_data.doc_id);
                otherVersion = otherVersion.$children[otherVersion.$children.length - 1];
                otherVersion.handlePanelExpand(otherVersion.companyDetails, api_call_type, false);
            }

            if (this.companyDetails?.key == "officer_companyWatch") this.$emit("fetchMoreInfo", details, api_call_type, recall);
            else this.handlePanelExpand(details, api_call_type, recall);
            // this.updateCollapse(api_call_type);
            this.$forceUpdate();
        },
        handlePanelExpand(details, type, recall = true) {
            details[`${type}Expanded`] = !details[`${type}Expanded`];
            if (this.isComparisonModeOn && recall) {
                let otherVersion = this.$parent.$parent.$children.find((el) => el.companyDetails.api_all_data.doc_id !== this.companyDetails.api_all_data.doc_id);
                otherVersion = otherVersion.$children[otherVersion.$children.length - 1];
                otherVersion.handlePanelExpand(otherVersion.companyDetails, type, false);
            }

            if (details.key == "companyWatch") {
                if (
                    details.summaryExpanded &&
                    details.detailExpanded &&
                    (details.addressesExpanded || !(details && details.api_all_data && details.api_all_data.addresses)) &&
                    details.accountsExpanded &&
                    (details.scoresExpanded || !(details && details.api_all_data && details.api_all_data.scores && details.api_all_data.scores.length)) &&
                    (details.financialExpanded || !(details && details.api_all_data && details.api_all_data.financial_ratios && details.api_all_data.financial_ratios.length)) &&
                    (details.directorsExpanded || !(this.isPrevOfficersTable || this.isOfficersTable)) &&
                    (details.shareholdersExpanded || !(details && details.api_all_data && details.api_all_data.shareholders && details.api_all_data.shareholders.length)) &&
                    (details.eventsExpanded || !(details && details.api_all_data && details.api_all_data.events && details.api_all_data.events.length))
                ) {
                    details.expandedPanel = true;
                } else {
                    details.expandedPanel = false;
                }
            } else if (details.key == "officer_companyWatch") {
                if (details.CurrentAppointmentsExpanded && details.PreviousAppointmentsExpanded) {
                    details.expandedPanel = true;
                } else {
                    details.expandedPanel = false;
                }
            }
            this.$emit("updateExpandAll", details.expandedPanel);
            if (this.isComparisonModeOn && !recall)
                setTimeout(() => {
                    this.highlightChanges();
                }, 100);
            this.$forceUpdate();
        },
        klReady(chart) {
            this.chart = chart;
            this.initialiseInteractions();
            this.chart.options({handMode: true});
            this.chart.layout("sequential", {tightness: 3, orientation: "down"});
        },
        initialiseInteractions() {
            // this.chart.on("context-menu", ({ id }) => {
            //     this.showSmartAnalyser = true
            //     const node = this.chart.getItem(id);
            //     var viewCoords = this.chart.viewCoordinates(node.x, node.y)
            //     var fakeStartButton = this.$refs['analyze-popup']['$el']
            //     fakeStartButton.style.position = "absolute"
            //     fakeStartButton.style.left = `${viewCoords.x + 300}px`
            //     fakeStartButton.style.top = `${viewCoords.y}px`
            //     this.selectedValue = node.label
            //     // this.selectedSource = (node.nodeType === 'link') ?  'url' ? (node.nodeType === 'company')  : node.nodeType
            //     if (node.nodeType === 'link') {
            //         this.selectedSource = 'url'
            //     }
            //     else if (node.nodeType === 'company') {
            //         this.selectedSource = 'company_name'
            //     }
            //     else {
            //         this.selectedSource = node.nodeType
            //     }
            //     this.$refs['analyze-popup'].$el.click()
            //     // e.preventDefault();
            //     // this.resetAnalyzerOptions()
            // });
        },
        async StabilizeGraph(klId) {
            this.stabilizing = true;
            await window.KeyLines.charts[`kl_corporate_records${klId}`].layout("sequential", {tightness: 3, orientation: "down"});
            this.stabilizing = false;
        },
        async getPrintdata(value, filename) {
            if (this.field_type === "company") {
                await EventBus.$emit("handleExpandAllPanel", {
                    details: this.companyDetails,
                    key: "companyWatch",
                    value: true,
                });
            } else {
                await EventBus.$emit("handleExpandAllPanel", {
                    details: this.companyDetails,
                    key: "officer_companyWatch",
                    value: true,
                });
            }

            // setTimeout(() => {
            this.$emit("updatePrintStatus", "");
            this.printData(value, filename);
        },
        getTableVal(obj, key) {
            return get(obj, key, "-") ? get(obj, key, "-") : "-";
        },
    },
};
