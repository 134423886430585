import { render, staticRenderFns } from "./radio.html?vue&type=template&id=a89612bc&scoped=true&external"
import script from "./radio.js?vue&type=script&lang=js&external"
export * from "./radio.js?vue&type=script&lang=js&external"
import style0 from "./radio.scss?vue&type=style&index=0&id=a89612bc&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a89612bc",
  null
  
)

export default component.exports