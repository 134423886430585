export default {
    name: "neo-tag",
    components: {},
    props: {
        tag: String,
        interactive: {
            type: Boolean,
            default: false,
        },
        bg: {
            type: String,
            default: "var(--color-danger-bg)",
        },
        textColor: {
            type: String,
            default: "var(--color-danger)",
        },
        iconBg: {
            type: String,
            default: "#faccd4",
        },
    },
    data() {
        return {};
    },
    computed: {
        getTag() {
            return typeof this.tag === "string" ? this.tag : this.tag.tag;
        },
    },
    mounted() {},
    methods: {},
};
