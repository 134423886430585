export default {
    name: "neo-multiselect",
    model: {
        prop: "value",
        event: "change",
    },
    props: {
        value: {
            type: [Array, String, Object],
        },
        options: [Array],
        trackBy: String,
        label: String,
        placeholder: {
            type: String,
            default: "Select",
        },
        multiselect: {
            type: Boolean,
            default: false,
        },
        limit: {
            type: Number,
            default: 99999,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        bg: {
            type: String,
            default: "#ffffff",
        },
        border: {
            type: String,
            default: "1px solid var(--brand-accent-neutral)",
        },
        placeholderColor: {
            type: String,
            default: "#000",
        },
        tags: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            selected: null,
            isOpen: false,
            selectedIndexs: [],
            limitText: "",
        };
    },
    computed: {
        selectedValue() {
            let tempSelected;
            if (this.selected) {
                if (this.multiselect) {
                    if (typeof this.selected === "object") {
                        tempSelected = this.value.map((el) => {
                            return el[this.label ? this.label : this.trackBy];
                        });
                    } else {
                        tempSelected = this.value;
                    }
                    let slicedValues = tempSelected.slice(0, this.limit);
                    if (tempSelected.length > this.limit) {
                        this.limitText = `${tempSelected.length - slicedValues.length} more`;
                    } else {
                        this.limitText = "";
                    }
                    tempSelected = slicedValues.join();
                } else {
                    if (typeof this.selected === "object") {
                        tempSelected = this.selected[this.label ? this.label : this.trackBy];
                    } else {
                        tempSelected = this.selected;
                    }
                }
                return tempSelected;
            }
        },

        getLabel() {
            if (typeof this.options[0] === "object") {
                return this.options.map((el) => {
                    return el[this.label ? this.label : this.trackBy];
                });
            } else {
                return this.options;
            }
        },
        getSelectedIndex() {
            let indexes = [];
            let index = -1;
            if (this.multiselect) {
                this.value.forEach((item) => {
                    if (typeof item === "object") {
                        index = this.options.findIndex((el) => el[this.trackBy] === item[this.trackBy]);
                    } else {
                        index = this.options.findIndex((el) => el === item);
                    }
                    if (index !== -1) {
                        indexes.push(index);
                    }
                });
            } else {
                if (typeof this.value === "object") {
                    index = this.options.findIndex((el) => el[this.trackBy] === this.value[this.trackBy]);
                } else {
                    index = this.options.findIndex((el) => el === this.value);
                }
                if (index !== -1) {
                    indexes.push(index);
                }
            }
            return indexes;
        },
    },
    mounted() {},
    methods: {
        onSelect(item, index) {
            if (this.multiselect) {
                this.onMultiSelect(item, index);
            } else {
                this.onSingleSelect(item, index);
            }
            this.isOpen = true;
        },
        onSingleSelect(item, index) {
            if (this.getSelectedIndex.includes(index)) {
                this.selected = typeof this.value === "object" ? {} : "";
            } else {
                this.selected = item;
            }
            this.$emit("change", this.selected);
        },

        onMultiSelect(item, index) {
            let tempValue = this.value ? this.value : [];
            if (this.getSelectedIndex.includes(index)) {
                if (typeof item === "object") {
                    tempValue.splice(
                        tempValue.findIndex((el) => el[this.trackBy] === item[this.trackBy]),
                        1
                    );
                } else {
                    tempValue.splice(tempValue.indexOf(item), 1);
                }
            } else {
                this.selected = item;
                tempValue.push(item);
            }

            this.$emit("change", tempValue);
        },

        activate() {
            if (this.isOpen) return;
            this.isOpen = true;
            this.$el.focus();
        },
        deactivate() {
            if (!this.isOpen) return;
            this.isOpen = false;
            this.$el.blur();
        },
        toggle() {
            this.isOpen ? this.deactivate() : this.activate();
        },
    },
};
