import Checkbox from "@/components/checkbox";
import loader from "@/components/loader";

import {startCase} from "lodash";

export default {
    name: "table",
    components: {
        "neo-checkbox": Checkbox,
        "neo-loader": loader,
    },
    props: ["isHeader", "headers", "items", "searchFilter", "filterKey", "itemKey", "selectable", "hasAction", "itemClass", "itemAction", "selectedItem", "loading", "actionClass"],
    data() {
        return {
            isCheckAll: false,
            selectedItems: [],
            startCase,
        };
    },
    computed: {
        colWidth() {
            let cols = this.headers.length;
            if (this.selectable) {
                cols += 1;
            }
            if (this.hasAction) {
                cols += 1;
            }
            return `${100 / cols}%`;
        },
        cols() {
            let cols = this.headers.length;
            if (this.selectable) {
                cols += 1;
            }
            if (this.hasAction) {
                cols += 1;
            }
            return cols;
        },
    },
    created() {
        if (this.isHeader !== false) {
            this.isHeader = true;
        }
    },
    methods: {
        checkAll: function () {
            this.isCheckAll = !this.isCheckAll;
            this.selectedItems = [];
            if (this.isCheckAll) {
                for (let i = 0; i < this.items.length; i++) {
                    const element = this.items[i];
                    this.selectedItems.push(element[this.itemKey]);
                }
            }
        },
        updateSelectedItems: function (key) {
            if (key) {
                if (this.selectedItems.includes(key)) {
                    this.selectedItems.splice(
                        this.selectedItems.findIndex((item) => item === key),
                        1
                    );
                } else {
                    this.selectedItems.push(key);
                }
            }
            if (this.selectedItems.length === this.items.length) {
                this.isCheckAll = true;
            } else {
                this.isCheckAll = false;
            }
        },
    },
};
